import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";

import * as styles from "./Hero.module.scss";
import CountSignatures from "./CountSignatures";
import FormSignature from "./Form-signature";

const Hero = ({ left, channel, backgroundClass, share }) => {
  const counter = channel ? <CountSignatures channel={channel} /> : "";

  const background = backgroundClass ? backgroundClass : "default-background";

  return (
    <div className={[styles.container + " " + background]} id="#">
      <div className={styles.wrap}>
        <div className={styles.content}>
          <GatsbyImage
            alt="Brasil é Solo Preto e Indígena"
            image={left}
            className={styles.left}
            style={{ margin: "0 auto" }}
          />
          <div className={styles.right}>
          <div className={styles.assignatures}>{counter}</div>
          <FormSignature />
          </div>   
        </div>
      </div>
    </div>
  );
};

Hero.propTypes = {
  channel: PropTypes.string,
  left: PropTypes.object,
  logo: PropTypes.object,
  right: PropTypes.object,
  backgroundClass: PropTypes.string,
  share: PropTypes.string,
};

export default Hero;
