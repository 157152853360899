// extracted by mini-css-extract-plugin
export var anchor = "Manifesto-module--anchor--adyln";
export var background = "Manifesto-module--background--+EZBS";
export var citations = "Manifesto-module--citations--II-P3";
export var container = "Manifesto-module--container--AH8Td";
export var formulario = "Manifesto-module--formulario--fL+9w";
export var instagram = "Manifesto-module--instagram--38tAp";
export var items = "Manifesto-module--items--vo4nH";
export var languages = "Manifesto-module--languages--EdFX1";
export var logo = "Manifesto-module--logo--0nORk";
export var manifesto = "Manifesto-module--manifesto--05sL7";
export var primeiroparagrafo = "Manifesto-module--primeiroparagrafo--pO9J+";
export var wrap = "Manifesto-module--wrap--mVF7w";