// extracted by mini-css-extract-plugin
export var assignatures = "Hero-module--assignatures--SAruT";
export var colorDark = "Hero-module--color-dark--InnD+";
export var container = "Hero-module--container--Ipc3S";
export var content = "Hero-module--content--106Q1";
export var left = "Hero-module--left--A4KqI";
export var logo = "Hero-module--logo--oueod";
export var textLeft = "Hero-module--text-left--UtLZU";
export var textRight = "Hero-module--text-right--OQliC";
export var title = "Hero-module--title--3RSKn";
export var wrap = "Hero-module--wrap--z9W-I";