import React from "react";
import PropTypes from "prop-types";

import * as styles from "./Manifesto.module.scss";
import { Form } from "@evag/ec-react";
import CampaignForm from "./CampaignForm";
import CampaignFeedback from "./CampaignFeedback";
import CountSignatures from "./CountSignatures";

const Formsignature = ({ share}) => (
  <div>
    <a className={styles.anchor} id="manifesto" />
    <div className={styles.formulario}>
      <CountSignatures></CountSignatures>
      <Form
        id="manifesto-form"
        className={styles.signaturesForm}
        forms={[
          CampaignForm,
          {
            ...CampaignFeedback,
            title: "Obrigada por assinar!",
            description: share,
          },
        ]}
      />
    </div>
  </div>
);

Formsignature.propTypes = {
  share: PropTypes.string,
};

export default Formsignature;
