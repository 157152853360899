import React from 'react';
import PropTypes from 'prop-types';

import ExtLink from './ExtLink';

import Facebook from '../svg/facebook.svg';
import Twitter from '../svg/twitter.svg';
import WhatsApp from '../svg/whatsapp.svg';
import Telegram from '../svg/telegram.svg';

const SocialNetworksShare = ({facebook, twitter, whatsapp, telegram}) => {
    const url = window.location.href;

    return (
        <React.Fragment>
            <ExtLink to={`https://facebook.com/sharer/sharer.php?u=${url}&quote=${facebook}`}><Facebook/></ExtLink>
            <ExtLink to={`https://twitter.com/intent/tweet?url=${url}&text=${twitter}&related=Website%0ALuana%0AAlves%0A@luanapsol&via=luanapsol`}><Twitter/></ExtLink>
            <ExtLink to={`https://t.me/share/url?url=${url}&text=${telegram}`}><Telegram/></ExtLink>
            <ExtLink to={`https://api.whatsapp.com/send?text=${whatsapp}%0A${url}`}><WhatsApp/></ExtLink>
        </React.Fragment>
    );
};

SocialNetworksShare.propTypes = {
    facebook: PropTypes.string,
    twitter: PropTypes.string,
    telegram: PropTypes.string,
    whatsapp: PropTypes.string,
};

export default SocialNetworksShare;

